
import { Component, Vue } from 'vue-property-decorator';
import Media from '@/ui-components/Media/Media.vue';
import CampaignInfo from '@/ui-components/CampaignInfo/CampaignInfo.vue';
import CampaignStatus from '@/ui-components/CampaignStatus/CampaignStatus.vue';
import CampaignRevenue from '@/ui-components/CampaignRevenue/CampaignRevenue.vue';
import HeaderTitle from './components/HeaderTitle.vue';
import { Action } from 'vuex-class';
import namespaces from '@/store/namespaces';
import { GetStoreCampaignById } from '@/store/modules/store-module/types';
import { StoreCampaign } from '@/types/campaigns';
import CampaignMedia from '@/ui-components/CampaignMedia/CampaignMedia.vue';
import { SYSTEM_STATUS } from '@/statics/system-status';

@Component({
  name: 'CampaignViewPage',
  components: {
    CampaignMedia,
    Media,
    HeaderTitle,
    CampaignStatus,
    CampaignRevenue,
    CampaignInfo,
  },
})
export default class CampaignViewPage extends Vue {
  @Action('getStoreCampaignById', {
    namespace: namespaces.StoreModule,
  })
  public getStoreCampaignById!: GetStoreCampaignById;

  public campaign: StoreCampaign | null = null;
  public isUpcomingCampaign = false;

  public get campaignId() {
    return this.$route.params.id;
  }

  public async getCampaignData() {
    this.campaign = await this.getStoreCampaignById(this.campaignId);
    if (this.campaign) {
      this.isUpcomingCampaign = this.campaign.status.VAL === SYSTEM_STATUS.UPCOMING.VAL;
    }
    if (!this.campaign || !this.campaignId) {
      this.$router.go(-1);
    }
  }

  public created() {
    this.getCampaignData();
  }
}
