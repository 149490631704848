
import { SystemStatus } from "@/types/misc";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
  name: "CampaignStatus",
})
export default class CampaignStatus extends Vue {
  @Prop({ type: Object }) status!: SystemStatus;

  public get statusValue() {
    return this.status.VAL.toLowerCase().replace('_', '-');
  }
}
