
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {},
  name: 'InfoCircle',
})
export default class InfoCircle extends Vue {
  @Prop({ type: String }) info!: string;
}
