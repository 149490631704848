
import { Component, Prop, Vue } from 'vue-property-decorator';
import InfoCircle from '@/ui-components/InfoCircle/InfoCircle.vue';
import { PendingCampaign } from '@/types/campaigns';

@Component({
  components: { InfoCircle },
})
export default class CampaignInfo extends Vue {
  @Prop({ type: Object, default: () => ({}) })
  public campaign!: PendingCampaign;

  public get campaignLocations() {
    return this.campaign.locations.map(({ BRANCH }) => BRANCH).join(', ');
  }

  public isOpen = false;

  public open() {
    this.isOpen = true;
  }

  public close() {
    this.isOpen = false;
  }
}
