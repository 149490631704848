
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'CampaignRevenue',
  components: {},
})
export default class CampaignRevenue extends Vue {
  @Prop({ type: Number, default: 0 })
  public earnedRevenue!: number;

  @Prop({ type: Number, default: 0 })
  public totalExpectedRevenue!: number;

  public get perentage() {
    const reveuePercentage = (this.earnedRevenue / this.totalExpectedRevenue) * 100;
    return Math.min(reveuePercentage, 100);
  }
}
