import { render, staticRenderFns } from "./InfoCircle.vue?vue&type=template&id=aa9704d0&scoped=true"
import script from "./InfoCircle.vue?vue&type=script&lang=ts"
export * from "./InfoCircle.vue?vue&type=script&lang=ts"
import style0 from "./InfoCircle.vue?vue&type=style&index=0&id=aa9704d0&prod&scoped=true&lang=sass"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa9704d0",
  null
  
)

export default component.exports