
import { MediaFile } from '@/types/media';
import Media from '@/ui-components/Media/Media.vue';
import MediaCarousel from '@/ui-components/MediaCarousel/MediaCarousel.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'CampaignMedia',
  components: {
    Media,
    MediaCarousel,
  },
})
export default class CampaignMedia extends Vue {
  @Prop({ type: Array, default: () => [] }) mediaList!: MediaFile[];
  @Prop({ type: Object, default: () => ({}) }) media!: MediaFile;
  @Prop({ type: Boolean, default: false }) public zoomable!: boolean;
  @Prop({ type: Boolean, default: false }) public playable!: boolean;
  @Prop({ type: Boolean, default: false }) public controls!: boolean;
  @Prop({ type: Boolean, default: false }) public dimensions!: boolean;
}
