
import { MediaFile } from '@/types/media';
import Media from '@/ui-components/Media/Media.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'MediaCarousel',
  components: {
    Media,
  },
})
export default class MediaCarousel extends Vue {
  @Prop({ type: Array, default: () => [] }) mediaList!: MediaFile[];
  @Prop({ type: Boolean, default: false }) compactControls!: boolean;
  @Prop({ type: Boolean, default: false }) public playable!: boolean;
  @Prop({ type: Boolean, default: false }) public zoomable!: boolean;
  @Prop({ type: Boolean, default: false }) public controls!: boolean;
  @Prop({ type: Boolean, default: false }) public dimensions!: boolean;

  public model = 0;
}
